import React from 'react';

const MatchInfo = ({ startDate, endDate, totalElapsed, leftScore, rightScore, awayPTSs, homePTSs, pace, possessions }) => {
  let arrPTSs;
  if (awayPTSs && homePTSs) {
    arrPTSs = [
      {away: awayPTSs[0], home: homePTSs[0]},
      {away: awayPTSs[1], home: homePTSs[1]},
      {away: awayPTSs[2], home: homePTSs[2]},
      {away: awayPTSs[3], home: homePTSs[3]}
    ]
    if (awayPTSs.length === 5) {
      arrPTSs.push({away: awayPTSs[4], home: homePTSs[4]});
    }
    if (awayPTSs.length === 2) {
      arrPTSs = [
        {away: awayPTSs[0], home: homePTSs[0]},
        {away: awayPTSs[1], home: homePTSs[1]},
      ]
    }
    if (awayPTSs.length === 3) {
      arrPTSs.push({away: awayPTSs[2], home: homePTSs[2]});
    }
  }
  return (
    <div className="flex flex-col items-center justify-center bg-white w-full space-y-4 md:space-y-8">
      <div className="hidden md:block text-base font-medium mt-1">{startDate * 1000 > new Date().getTime() || endDate * 1000 < new Date().getTime() || startDate === 0 ? ("") : (new Date(startDate * 1000).toLocaleString())}</div>
      <div className="text-base font-medium mt-1">{(totalElapsed === 0 || totalElapsed === undefined || startDate * 1000 > new Date().getTime() || endDate * 1000 < new Date().getTime() || startDate === 0 || endDate === 0) ? ("") : (`REMAINING MINUTES: ${12 - Math.floor(totalElapsed / 60) % 12}min`)}</div>
      <div className="flex flex-col items-center space-y-2">
        <div className="text-4xl font-bold">{`${leftScore} : ${rightScore}`}</div>
        <div className="flex justify-center items-center">
          {arrPTSs?.map((arrPTS, index) => (
            <div key={index} className="flex items-center">
              <div className="text-xl font-bold">{`${arrPTS.away}-${arrPTS.home}`}</div>
              {index < arrPTSs.length - 1 && (
                <div className="border-r border-gray-300 mx-3 h-6"></div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center space-y-2">
        <div className="text-base font-medium mt-1">POSSESSIONS PER MINUTE</div>
        <div className="text-2xl font-bold">{pace}</div>
      </div>
      <div className="flex justify-center items-center">
        {possessions?.map((possession, index) => (
          <div key={index} className="flex items-center">
            <div className="text-xl font-bold">{possession}</div>
            {index < possessions.length - 1 && (
              <div className="border-r border-gray-300 mx-3 h-6"></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchInfo;