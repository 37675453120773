import React, { useState } from "react";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="navbar bg-base-100 sticky top-0 z-50 px-2 sm:px-4" data-theme="dark">
      <div className="flex-1">
        <a href="/" className="btn btn-ghost normal-case text-xl sm:text-2xl text-white">
          NBA Live Analyzer
        </a>
      </div>
      <div className="flex-none hidden sm:block">
        <a href="/getLasts" className="btn btn-ghost normal-case text-xs sm:text-sm text-white">
          Last Matches
        </a>
        <a href="/getUpcomings" className="btn btn-ghost normal-case text-xs sm:text-sm text-white">
          Upcoming Matches
        </a>
        <a href="/getLives" className="btn btn-ghost normal-case text-xs sm:text-sm text-white">
          Live Matches
        </a>
      </div>
      <div className="flex-none sm:hidden">
        <button className="btn btn-square btn-ghost" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className="space-y-1">
            <span className="block w-5 h-0.5 bg-white"></span>
            <span className="block w-5 h-0.5 bg-white"></span>
            <span className="block w-5 h-0.5 bg-white"></span>
          </div>
        </button>
      </div>
      <div className={`absolute bg-white top-full right-0 w-full sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <a href="/getLasts" className="btn btn-ghost w-full justify-end normal-case text-xl text-black">
          Last Matches
        </a>
        <a href="/getUpcomings" className="btn btn-ghost w-full justify-end normal-case text-xl text-black">
          Upcoming Matches
        </a>
        <a href="/getLives" className="btn btn-ghost w-full justify-end normal-case text-xl text-black">
          Live Matches
        </a>
      </div>
    </div>
  );
}

export default NavBar;
