import React, { useEffect, useState } from 'react';
import Analysis from "./analysis";
import { getTodayMatches, getLastMatches, getUpcomingMatches, getLiveMatches } from '../lib/basketAPI';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Table = ({ category }) => {

  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState([]);
  // const [todayMatches, setTodayMatches] = useState([]);
  // const [lastMatches, setLastMatches] = useState([]);
  // const [upcomingMatches, setUpcomingMatches] = useState([]);
  // const [liveMatches, setLiveMatches] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      switch(category){
        case "todayMatches":
          const today = await getTodayMatches();
          setMatches(today || []);
          // matches = todayMatches;
          break;
        case "lastMatches":
          const last = await getLastMatches();
          setMatches(last || []);
          break;
        case "upcomingMatches":
          const upcoming = await getUpcomingMatches();
          setMatches(upcoming || []);
          break;
        case "liveMatches":
          const live = await getLiveMatches();
          setMatches(live || []);
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      if (!init) setInit(true);
    }
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, []);


  return (
    <>
      {(loading && !init) ? (
          <div className="flex-grow bg-white text-center p-10 flex justify-center items-center">
            <Box>
              <CircularProgress />
            </Box>
          </div>
        ) :
      (<div className="bg-white grid grid-cols-1 divide-y text-black">
        {matches && matches.length > 0 ? (
          matches.map((match, index) => (
            // match?.stat != null &&
            <div className="bg-white py-2" key={index}>
              <Analysis match={match} />
            </div>
          ))
        ) : (
          <div className="py-10 text-center text-2xl font-bold">No Matches</div>
        )}
      </div>)}
    </>
  );
}

export default Table;