import React from "react";

const TeamInfo = ({ teamName, teamCode, teamLogo, curRound, fouls, twoPT, threePT, pace }) => {
  return (
    <div className="flex md:flex-col items-center justify-around h-full p-4 space-x-4 md:space-y-8">
      <div className="flex flex-col items-center">
        <div className="hidden md:block text-2xl font-bold mb-2">{teamName}</div>
        {/* {teamCode.toLowerCase() === "uta" ? (
          <img src={`https://a.espncdn.com/i/teamlogos/nba/500/scoreboard/UTAH.png`} alt="Logo" className="w-24 h-24 md:w-32 md:h-32 mb-1" />
        ) : teamCode.toLowerCase() === "nop" ? (
          <img src={`https://a.espncdn.com/i/teamlogos/nba/500/scoreboard/NO.png`} alt="Logo" className="w-24 h-24 md:w-32 md:h-32 mb-1" />
        ) : (
          <img src={`https://a.espncdn.com/i/teamlogos/nba/500/scoreboard/${teamCode}.png`} alt="Logo" className="w-24 h-24 md:w-32 md:h-32 mb-1" />
        )} */}
        {/* {teamCode.toLowerCase() === "uta" ? (
          <img src={`http://35.78.80.226:5000/UTAH.jpg`} alt="Logo" className="w-24 h-24 md:w-32 md:h-32 mb-1" />
        ) : teamCode.toLowerCase() === "nop" ? (
          <img src={`http://35.78.80.226:5000/NO.jpg`} alt="Logo" className="w-24 h-24 md:w-32 md:h-32 mb-1" />
        ) : ( */}
          <img src={`${teamLogo}`} alt="Logo" className="w-24 h-24 md:w-32 md:h-32 mb-1" />
        {/* )} */}
      </div>
      <div className="text-center">
        <div className="text-2xl font-bold">{fouls}</div>
        <div className="text-xl mt-1">FOULS</div>
      </div>
      <div className="text-center">
        <div className="text-2xl font-bold">{twoPT}</div>
        <div className="text-xl mt-1">2-PT %</div>
      </div>
      <div className="text-center">
        <div className="text-2xl font-bold">{threePT}</div>
        <div className="text-xl mt-1">3-PT %</div>
      </div>
      <div className="text-center">
        <div className="text-2xl font-bold">{pace}</div>
        <div className="text-xl mt-1">PACE</div>
      </div>
    </div>
  );
};

export default TeamInfo;