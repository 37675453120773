import React, { useEffect, useState, useCallback } from "react";
import TeamInfo from "./teaminfo";
import MatchInfo from "./matchinfo";

const Analysis = ({ match }) => {
  const [matchInfo, setMatchInfo] = useState([]);
  const [totalElapsed, setTotalElapsed] = useState();
  const [firstRoundElapsed, setFirstRoundElapsed] = useState();
  const [secondRoundElapsed, setSecondRoundElapsed] = useState();
  const [thirdRoundElapsed, setThirdRoundElapsed] = useState();
  const [fourthRoundElapsed, setFourthRoundElapsed] = useState();
  const [overtimeElapsed, setOvertimeElapsed] = useState();
  const [currentRound, setCurrentRound] = useState();
  const [poss, setPoss] = useState();
  const [awayPTS, setAwayPTS] = useState();
  const [homePTS, setHomePTS] = useState();

  const setElapsedTimes = useCallback((first, second, third, fourth, overtime, total) => {
    setFirstRoundElapsed(first);
    setSecondRoundElapsed(second);
    setThirdRoundElapsed(third);
    setFourthRoundElapsed(fourth);
    setOvertimeElapsed(overtime);
    setTotalElapsed(total);
  }, [setFirstRoundElapsed, setSecondRoundElapsed, setThirdRoundElapsed, setFourthRoundElapsed, setOvertimeElapsed, setTotalElapsed]);

  const calculateElapsedTimes = useCallback((totalTime, description, type, roundTime) => {
    if (type.toLowerCase() === "finished") {
      setCurrentRound(0);
      if (description.toLowerCase() === "aet") {        
        if(match?.time.periodLength == 1200){
          setElapsedTimes(roundTime, roundTime, 0, 0, 300, roundTime * 2 + 300);
        }
        else{
          setElapsedTimes(roundTime, roundTime, roundTime, roundTime, 300, roundTime * 4 + 300);
        }
        
      } else {
        if(match?.time.periodLength == 1200){
          setElapsedTimes(roundTime, roundTime, 0, 0, 0, roundTime * 2);
        }
        else{
          setElapsedTimes(roundTime, roundTime, roundTime, roundTime, 0, roundTime * 4);
        }
        
      }
      // console.log("called", match?.id);
    } else if (type.toLowerCase() === "inprogress") {
      if (totalTime % roundTime === 1)
        totalTime = totalTime - 1;
      if (description.toLowerCase() === "pause") {
        if (match?.stat?.length === 2) {
          setCurrentRound(1);
          setElapsedTimes(roundTime, 0, 0, 0, 0, roundTime);
        } else if (match?.stat.length === 3) {
          setCurrentRound(2);
          setElapsedTimes(roundTime, roundTime, 0, 0, 0, roundTime*2);
        } else if (match?.stat.length === 4) {
          setCurrentRound(3);
          setElapsedTimes(roundTime, roundTime, roundTime, 0, 0, roundTime*3);
        } else if (match?.stat.length === 5) {
          setCurrentRound(4);
          setElapsedTimes(roundTime, roundTime, roundTime, roundTime, 0, roundTime*4);
        } else if (match?.stat.length === 6) {
          setCurrentRound(5);
          setElapsedTimes(roundTime, roundTime, roundTime, roundTime, 300, roundTime*4+300);
        } else {
          setCurrentRound(6);
          setElapsedTimes(0, 0, 0, 0, 0, 0);
        }
      } else if (description.toLowerCase() === "1st quarter" || (match?.time.periodLength == 1200 && description.toLowerCase() === "1st half") || (match?.time.periodLength == 1200 && description.toLowerCase() === "halftime")) {
        setElapsedTimes(totalTime, 0, 0, 0, 0, totalTime);
        setCurrentRound(1);
      } else if (description.toLowerCase() === "2nd quarter" || (match?.time.periodLength != 1200 && description.toLowerCase() === "1st half") || (match?.time.periodLength == 1200 && description.toLowerCase() === "2nd half") || (match?.time.periodLength != 1200 && description.toLowerCase() === "halftime")) {
        setCurrentRound(2);
        if (totalTime > roundTime) {
          setElapsedTimes(roundTime, totalTime - roundTime, 0, 0, 0, totalTime);
        } else {
          setElapsedTimes(roundTime, totalTime, 0, 0, 0, totalTime + roundTime);
        }
      } else if (description.toLowerCase() === "3rd quarter" || description.toLowerCase() === "2nd half") {
        setCurrentRound(3);
        if (totalTime > roundTime) {
          setElapsedTimes(roundTime, roundTime, totalTime - roundTime * 2, 0, 0, totalTime);
        } else {
          setElapsedTimes(roundTime, roundTime, totalTime, 0, 0, totalTime + roundTime * 2);
        }
      } else if (description.toLowerCase() === "4th quarter" || description.toLowerCase() === "3rd half") {
        setCurrentRound(4);
        if (totalTime > roundTime) {
          setElapsedTimes(roundTime, roundTime, roundTime, totalTime - roundTime * 3, 0, totalTime);
        } else {
          setElapsedTimes(roundTime, roundTime, roundTime, totalTime, 0, totalTime + roundTime * 3);
        }
      } else if (description.toLowerCase() === "overtime" || description.toLowerCase() === "awaiting extra time" || description.toLowerCase() === "ended") {
        setCurrentRound(5);
        if (totalTime > roundTime) {
          if(match?.time.periodLength == 1200){
            setElapsedTimes(roundTime, roundTime, 0, 0, totalTime - roundTime * 2, totalTime);
          }
          else{
            setElapsedTimes(roundTime, roundTime, roundTime, roundTime, totalTime - roundTime * 4, totalTime);
          }
          
        } else {
          if(match?.time.periodLength == 1200){
            setElapsedTimes(roundTime, roundTime, 0, 0, totalTime, totalTime + roundTime * 2);
          }
          else{
            setElapsedTimes(roundTime, roundTime, roundTime, roundTime, totalTime, totalTime + roundTime * 4);
          }
          
        }
      } else {
        setCurrentRound(6);
        setElapsedTimes(0, 0, 0, 0, 0, 0);
      }
    } else {
      setCurrentRound(6);
      setElapsedTimes(0, 0, 0, 0, 0, 0);
    }
  }, [match, setCurrentRound, setElapsedTimes]);

  const calculateRoundPossessions = useCallback(() => {
    let first;
    let firstAwayPTS;
    let firstHomePTS;
    let second;
    let secondAwayPTS;
    let secondHomePTS;
    let third;
    let thirdAwayPTS;
    let thirdHomePTS;
    let fourth;
    let fourthAwayPTS;
    let fourthHomePTS;
    let overtime;
    let overtimeAwayPTS;
    let overtimeHomePTS;

    if (firstRoundElapsed !== 0 &&
        matchInfo[1]?.groups[0]?.statisticsItems[3]?.awayTotal !== undefined && 
        matchInfo[1]?.groups[0]?.statisticsItems[0]?.awayTotal !== undefined &&
        matchInfo[1]?.groups[1]?.statisticsItems[4]?.awayValue !== undefined &&
        matchInfo[1]?.groups[0]?.statisticsItems[3]?.homeTotal !== undefined && 
        matchInfo[1]?.groups[0]?.statisticsItems[0]?.homeTotal !== undefined &&
        matchInfo[1]?.groups[1]?.statisticsItems[4]?.homeValue !== undefined) {
      first = ((matchInfo[1]?.groups[0]?.statisticsItems[3]?.homeTotal +
                matchInfo[1]?.groups[0]?.statisticsItems[3]?.awayTotal +
                (matchInfo[1]?.groups[0]?.statisticsItems[0]?.homeTotal +
                matchInfo[1]?.groups[0]?.statisticsItems[0]?.awayTotal) * 2 / 5 +
                matchInfo[1]?.groups[1]?.statisticsItems[4]?.homeValue + 
                matchInfo[1]?.groups[1]?.statisticsItems[4]?.awayValue) /
                (firstRoundElapsed / 60)).toFixed(2);
      firstAwayPTS = matchInfo[1]?.groups[0]?.statisticsItems[0].awayValue + 
                    matchInfo[1]?.groups[0]?.statisticsItems[1].awayValue * 2 +
                    matchInfo[1]?.groups[0]?.statisticsItems[2].awayValue * 3;
      firstHomePTS = matchInfo[1]?.groups[0]?.statisticsItems[0].homeValue + 
                    matchInfo[1]?.groups[0]?.statisticsItems[1].homeValue * 2 +
                    matchInfo[1]?.groups[0]?.statisticsItems[2].homeValue * 3;
    } else {
      first = 0; firstAwayPTS = match?.awayScore?.period1; firstHomePTS = match?.homeScore?.period1;
    }

    if (secondRoundElapsed !== 0 &&
        matchInfo[2]?.groups[0]?.statisticsItems[3]?.awayTotal !== undefined && 
        matchInfo[2]?.groups[0]?.statisticsItems[0]?.awayTotal !== undefined &&
        matchInfo[2]?.groups[1]?.statisticsItems[4]?.awayValue !== undefined &&
        matchInfo[2]?.groups[0]?.statisticsItems[3]?.homeTotal !== undefined && 
        matchInfo[2]?.groups[0]?.statisticsItems[0]?.homeTotal !== undefined &&
        matchInfo[2]?.groups[1]?.statisticsItems[4]?.homeValue !== undefined) {
      second = ((matchInfo[2]?.groups[0]?.statisticsItems[3]?.homeTotal +
                matchInfo[2]?.groups[0]?.statisticsItems[3]?.awayTotal +
                (matchInfo[2]?.groups[0]?.statisticsItems[0]?.homeTotal +
                matchInfo[2]?.groups[0]?.statisticsItems[0]?.awayTotal) * 2 / 5 +
                matchInfo[2]?.groups[1]?.statisticsItems[4]?.homeValue + 
                matchInfo[2]?.groups[1]?.statisticsItems[4]?.awayValue) /
                (secondRoundElapsed / 60)).toFixed(2);
      secondAwayPTS = matchInfo[2]?.groups[0]?.statisticsItems[0].awayValue + 
                      matchInfo[2]?.groups[0]?.statisticsItems[1].awayValue * 2 +
                      matchInfo[2]?.groups[0]?.statisticsItems[2].awayValue * 3;
      secondHomePTS = matchInfo[2]?.groups[0]?.statisticsItems[0].homeValue + 
                      matchInfo[2]?.groups[0]?.statisticsItems[1].homeValue * 2 +
                      matchInfo[2]?.groups[0]?.statisticsItems[2].homeValue * 3;
    } else {
      second = 0; secondAwayPTS = match?.awayScore?.period2; secondHomePTS = match?.homeScore?.period2;
    }

    if (thirdRoundElapsed !== 0 &&
        matchInfo[3]?.groups[0]?.statisticsItems[3]?.awayTotal !== undefined && 
        matchInfo[3]?.groups[0]?.statisticsItems[0]?.awayTotal !== undefined &&
        matchInfo[3]?.groups[1]?.statisticsItems[4]?.awayValue !== undefined &&
        matchInfo[3]?.groups[0]?.statisticsItems[3]?.homeTotal !== undefined && 
        matchInfo[3]?.groups[0]?.statisticsItems[0]?.homeTotal !== undefined &&
        matchInfo[3]?.groups[1]?.statisticsItems[4]?.homeValue !== undefined) {
      third = ((matchInfo[3]?.groups[0]?.statisticsItems[3]?.homeTotal +
                matchInfo[3]?.groups[0]?.statisticsItems[3]?.awayTotal +
                (matchInfo[3]?.groups[0]?.statisticsItems[0]?.homeTotal +
                matchInfo[3]?.groups[0]?.statisticsItems[0]?.awayTotal) * 2 / 5 +
                matchInfo[3]?.groups[1]?.statisticsItems[4]?.homeValue + 
                matchInfo[3]?.groups[1]?.statisticsItems[4]?.awayValue) /
                (thirdRoundElapsed / 60)).toFixed(2);
      thirdAwayPTS = matchInfo[3]?.groups[0]?.statisticsItems[0].awayValue + 
                    matchInfo[3]?.groups[0]?.statisticsItems[1].awayValue * 2 +
                    matchInfo[3]?.groups[0]?.statisticsItems[2].awayValue * 3;
      thirdHomePTS = matchInfo[3]?.groups[0]?.statisticsItems[0].homeValue + 
                    matchInfo[3]?.groups[0]?.statisticsItems[1].homeValue * 2 +
                    matchInfo[3]?.groups[0]?.statisticsItems[2].homeValue * 3;
    } else {
      third = 0; thirdAwayPTS = match?.awayScore?.period3; thirdHomePTS = match?.homeScore?.period3;
    }

    if (fourthRoundElapsed !== 0 &&
        matchInfo[4]?.groups[0]?.statisticsItems[3]?.awayTotal !== undefined && 
        matchInfo[4]?.groups[0]?.statisticsItems[0]?.awayTotal !== undefined &&
        matchInfo[4]?.groups[1]?.statisticsItems[4]?.awayValue !== undefined &&
        matchInfo[4]?.groups[0]?.statisticsItems[3]?.homeTotal !== undefined && 
        matchInfo[4]?.groups[0]?.statisticsItems[0]?.homeTotal !== undefined &&
        matchInfo[4]?.groups[1]?.statisticsItems[4]?.homeValue !== undefined) {
      fourth = ((matchInfo[4]?.groups[0]?.statisticsItems[3]?.homeTotal +
                matchInfo[4]?.groups[0]?.statisticsItems[3]?.awayTotal +
                (matchInfo[4]?.groups[0]?.statisticsItems[0]?.homeTotal +
                matchInfo[4]?.groups[0]?.statisticsItems[0]?.awayTotal) * 2 / 5 +
                matchInfo[4]?.groups[1]?.statisticsItems[4]?.homeValue + 
                matchInfo[4]?.groups[1]?.statisticsItems[4]?.awayValue) /
                (fourthRoundElapsed / 60)).toFixed(2);
      fourthAwayPTS = matchInfo[4]?.groups[0]?.statisticsItems[0].awayValue + 
                      matchInfo[4]?.groups[0]?.statisticsItems[1].awayValue * 2 +
                      matchInfo[4]?.groups[0]?.statisticsItems[2].awayValue * 3;
      fourthHomePTS = matchInfo[4]?.groups[0]?.statisticsItems[0].homeValue + 
                      matchInfo[4]?.groups[0]?.statisticsItems[1].homeValue * 2 +
                      matchInfo[4]?.groups[0]?.statisticsItems[2].homeValue * 3;
    } else {
      fourth = 0; fourthAwayPTS = match?.awayScore?.period4; fourthHomePTS = match?.homeScore?.period4;
    }

    if (overtimeElapsed !== 0 &&
        matchInfo[5]?.groups[0]?.statisticsItems[3]?.awayTotal !== undefined && 
        matchInfo[5]?.groups[0]?.statisticsItems[0]?.awayTotal !== undefined &&
        matchInfo[5]?.groups[1]?.statisticsItems[4]?.awayValue !== undefined &&
        matchInfo[5]?.groups[0]?.statisticsItems[3]?.homeTotal !== undefined && 
        matchInfo[5]?.groups[0]?.statisticsItems[0]?.homeTotal !== undefined &&
        matchInfo[5]?.groups[1]?.statisticsItems[4]?.homeValue !== undefined) {
      overtime = ((matchInfo[5]?.groups[0]?.statisticsItems[3]?.homeTotal +
                  matchInfo[5]?.groups[0]?.statisticsItems[3]?.awayTotal +
                  (matchInfo[5]?.groups[0]?.statisticsItems[0]?.homeTotal +
                  matchInfo[5]?.groups[0]?.statisticsItems[0]?.awayTotal) * 2 / 5 +
                  matchInfo[5]?.groups[1]?.statisticsItems[4]?.homeValue + 
                  matchInfo[5]?.groups[1]?.statisticsItems[4]?.awayValue) /
                  (overtimeElapsed / 60)).toFixed(2);
      overtimeAwayPTS = matchInfo[5]?.groups[0]?.statisticsItems[0].awayValue + 
                        matchInfo[5]?.groups[0]?.statisticsItems[1].awayValue * 2 +
                        matchInfo[5]?.groups[0]?.statisticsItems[2].awayValue * 3;
      overtimeHomePTS = matchInfo[5]?.groups[0]?.statisticsItems[0].homeValue + 
                        matchInfo[5]?.groups[0]?.statisticsItems[1].homeValue * 2 +
                        matchInfo[5]?.groups[0]?.statisticsItems[2].homeValue * 3;
    } else {
      overtime = 0; overtimeAwayPTS = 0; overtimeHomePTS = 0;
    }

    let arrPoss = [];
    let arrAwayPTS = [];
    let arrHomePTS = [];
 
    
    if(match?.time.periodLength == 1200){
      if (first > 0) {arrPoss.push(first);} else {arrPoss.push(0);}
      if (second > 0) {arrPoss.push(second);} else {arrPoss.push(0);}
      if (overtime > 0) {arrPoss.push(overtime);}

      if (firstAwayPTS > 0) {arrAwayPTS.push(firstAwayPTS);} else {arrAwayPTS.push(0);}
      if (secondAwayPTS  > 0) {arrAwayPTS.push(secondAwayPTS);} else {arrAwayPTS.push(0);}
      if (overtimeAwayPTS  > 0) {arrAwayPTS.push(overtimeAwayPTS);}

      if (firstHomePTS > 0) {arrHomePTS.push(firstHomePTS);} else {arrHomePTS.push(0);}
      if (secondHomePTS > 0) {arrHomePTS.push(secondHomePTS);} else {arrHomePTS.push(0);}
      if (overtimeHomePTS > 0) {arrHomePTS.push(overtimeHomePTS);}

      console.log("ffff--->", first, second, third, fourth, arrPoss, arrAwayPTS);
    }
    else{
      if (first > 0) {arrPoss.push(first);} else {arrPoss.push(0);}
      if (second > 0) {arrPoss.push(second);} else {arrPoss.push(0);}
      if (third > 0) {arrPoss.push(third);} else {arrPoss.push(0);}
      if (fourth > 0) {arrPoss.push(fourth);} else {arrPoss.push(0);}
      if (overtime > 0) {arrPoss.push(overtime);}

      if (firstAwayPTS > 0) {arrAwayPTS.push(firstAwayPTS);} else {arrAwayPTS.push(0);}
      if (secondAwayPTS  > 0) {arrAwayPTS.push(secondAwayPTS);} else {arrAwayPTS.push(0);}
      if (thirdAwayPTS  > 0) {arrAwayPTS.push(thirdAwayPTS);} else {arrAwayPTS.push(0);}
      if (fourthAwayPTS  > 0) {arrAwayPTS.push(fourthAwayPTS);} else {arrAwayPTS.push(0);}
      if (overtimeAwayPTS  > 0) {arrAwayPTS.push(overtimeAwayPTS);}

      if (firstHomePTS > 0) {arrHomePTS.push(firstHomePTS);} else {arrHomePTS.push(0);}
      if (secondHomePTS > 0) {arrHomePTS.push(secondHomePTS);} else {arrHomePTS.push(0);}
      if (thirdHomePTS > 0) {arrHomePTS.push(thirdHomePTS);} else {arrHomePTS.push(0);}
      if (fourthHomePTS > 0) {arrHomePTS.push(fourthHomePTS);} else {arrHomePTS.push(0);}
      if (overtimeHomePTS > 0) {arrHomePTS.push(overtimeHomePTS);}
    }
      
    setPoss(arrPoss);
    
    setAwayPTS(arrAwayPTS);
    
    setHomePTS(arrHomePTS);
  }, [matchInfo, firstRoundElapsed, secondRoundElapsed, thirdRoundElapsed, fourthRoundElapsed, overtimeElapsed]);
  // console.log("elap------>", firstRoundElapsed, secondRoundElapsed, thirdRoundElapsed, fourthRoundElapsed, overtimeElapsed, match?.awayTeam.id);
  useEffect(() => {
    if (!match?.stat) {
      if(match?.time.periodLength == 1200){  //if ncaa-men
        setPoss([0, 0]);
      }
      else{
        setPoss([0, 0, 0, 0]);
      }
      setAwayPTS([match?.awayScore?.period1||0, match?.awayScore?.period2||0, match?.awayScore?.period3||0, match?.awayScore?.period4||0]);
      setHomePTS([match?.homeScore?.period1||0, match?.homeScore?.period2||0, match?.homeScore?.period3||0, match?.homeScore?.period4||0]);
      return;
    }

    setMatchInfo(match?.stat);
    calculateElapsedTimes(match?.time?.played, match?.status?.description, match?.status?.type, match?.time.periodLength);
    calculateRoundPossessions();
  }, [match, calculateElapsedTimes, calculateRoundPossessions]);
  if(match?.id == 12168778)
  console.log("--->ID, totalTime", match?.id, totalElapsed);
  return (

    <div className="flex flex-col md:flex-row justify-between items-stretch w-full bg-white">
      <div className="md:flex-1 p-2">
        <TeamInfo
          teamName={match?.awayTeam.name}
          teamCode={match?.awayTeam.nameCode}
          teamLogo={match?.awayTeamLogoUrl}
          curRound={currentRound}
          fouls={(matchInfo[currentRound]?.groups[1]?.statisticsItems[7]?.away || "0")}
          twoPT={((matchInfo[0]?.groups[0]?.statisticsItems[1]?.awayTotal !== 0 && 
                  matchInfo[0]?.groups[0]?.statisticsItems[1]?.awayTotal !== undefined &&
                  matchInfo[0]?.groups[0]?.statisticsItems[1]?.awayValue !== undefined) ?
                ((matchInfo[0]?.groups[0]?.statisticsItems[1]?.awayValue * 100 /
                  matchInfo[0]?.groups[0]?.statisticsItems[1]?.awayTotal).toFixed(0)) :
                "0")}
          threePT={((matchInfo[0]?.groups[0]?.statisticsItems[2]?.awayTotal !== 0 && 
                    matchInfo[0]?.groups[0]?.statisticsItems[2]?.awayTotal !== undefined &&
                    matchInfo[0]?.groups[0]?.statisticsItems[2]?.awayValue !== undefined) ?
                  ((matchInfo[0]?.groups[0]?.statisticsItems[2]?.awayValue * 100 /
                    matchInfo[0]?.groups[0]?.statisticsItems[2]?.awayTotal).toFixed(0)) :
                  "0")}
          pace={((totalElapsed !== 0 &&
                  matchInfo[0]?.groups[0]?.statisticsItems[3]?.awayTotal !== undefined && 
                  matchInfo[0]?.groups[0]?.statisticsItems[0]?.awayTotal !== undefined &&
                  matchInfo[0]?.groups[1]?.statisticsItems[4]?.awayValue !== undefined) ?
                (((matchInfo[0]?.groups[0]?.statisticsItems[3]?.awayTotal +
                  matchInfo[0]?.groups[0]?.statisticsItems[0]?.awayTotal * 2 / 5 +
                  matchInfo[0]?.groups[1]?.statisticsItems[4]?.awayValue) /
                  (totalElapsed / 60)).toFixed(2))
                  : "0")}
        />
      </div>
      <div className="md:flex-1 p-2 md:pt-16 md:mx-4">
        <MatchInfo
          startDate={match?.startTimestamp || "0"}
          endDate={match?.endTimestamp || "0"}
          totalElapsed={totalElapsed || "0"}
          leftScore={match?.awayScore?.current || "0"}
          rightScore={match?.homeScore?.current || "0"}
          awayPTSs={awayPTS}
          homePTSs={homePTS}
          pace={((totalElapsed !== 0 &&
                  matchInfo[0]?.groups[0]?.statisticsItems[3]?.awayTotal !== undefined && 
                  matchInfo[0]?.groups[0]?.statisticsItems[0]?.awayTotal !== undefined &&
                  matchInfo[0]?.groups[1]?.statisticsItems[4]?.awayValue !== undefined &&
                  matchInfo[0]?.groups[0]?.statisticsItems[3]?.homeTotal !== undefined && 
                  matchInfo[0]?.groups[0]?.statisticsItems[0]?.homeTotal !== undefined &&
                  matchInfo[0]?.groups[1]?.statisticsItems[4]?.homeValue !== undefined) ?
                (((matchInfo[0]?.groups[0]?.statisticsItems[3]?.homeTotal +
                  matchInfo[0]?.groups[0]?.statisticsItems[3]?.awayTotal +
                  (matchInfo[0]?.groups[0]?.statisticsItems[0]?.homeTotal +
                  matchInfo[0]?.groups[0]?.statisticsItems[0]?.awayTotal) * 2 / 5 +
                  matchInfo[0]?.groups[1]?.statisticsItems[4]?.homeValue + 
                  matchInfo[0]?.groups[1]?.statisticsItems[4]?.awayValue) /
                  (totalElapsed / 60)).toFixed(2))
                  : "0")}
          possessions={poss}
        />
      </div>
      <div className="md:flex-1 p-2">
        <TeamInfo
          teamName={match?.homeTeam.name}
          teamCode={match?.homeTeam.nameCode}
          teamLogo={match?.homeTeamLogoUrl}
          curRound={currentRound}
          fouls={(matchInfo[currentRound]?.groups[1]?.statisticsItems[7]?.home || "0")}
          twoPT={((matchInfo[0]?.groups[0]?.statisticsItems[1]?.awayTotal !== 0 && 
                  matchInfo[0]?.groups[0]?.statisticsItems[1]?.homeTotal !== undefined &&
                  matchInfo[0]?.groups[0]?.statisticsItems[1]?.homeValue !== undefined) ?
                ((matchInfo[0]?.groups[0]?.statisticsItems[1]?.homeValue * 100 /
                  matchInfo[0]?.groups[0]?.statisticsItems[1]?.homeTotal).toFixed(0)) :
                "0")}
          threePT={((matchInfo[0]?.groups[0]?.statisticsItems[2]?.homeTotal !== 0 && 
                    matchInfo[0]?.groups[0]?.statisticsItems[2]?.homeTotal !== undefined &&
                    matchInfo[0]?.groups[0]?.statisticsItems[2]?.homeValue !== undefined) ?
                  ((matchInfo[0]?.groups[0]?.statisticsItems[2]?.homeValue * 100 /
                    matchInfo[0]?.groups[0]?.statisticsItems[2]?.homeTotal).toFixed(0)) :
                  "0")}
          pace={((totalElapsed !== 0 &&
                  matchInfo[0]?.groups[0]?.statisticsItems[3]?.homeTotal !== undefined && 
                  matchInfo[0]?.groups[0]?.statisticsItems[0]?.homeTotal !== undefined &&
                  matchInfo[0]?.groups[1]?.statisticsItems[4]?.homeValue !== undefined) ?
                (((matchInfo[0]?.groups[0]?.statisticsItems[3]?.homeTotal +
                  matchInfo[0]?.groups[0]?.statisticsItems[0]?.homeTotal * 2 / 5 +
                  matchInfo[0]?.groups[1]?.statisticsItems[4]?.homeValue) /
                  (totalElapsed / 60)).toFixed(2))
                  : "0")}
        />
      </div>
    </div>
                
  );
}

export default Analysis;