import axios from "axios";
// import axios from "../utils/axios";

export const postInfoResponse = async (url_, postData_) => {
  let _response = await axios
    .post(url_, postData_)
    .catch((error) => console.log('Error: ', error));
  if (_response && _response.data) {
    return _response;
  } else {
    // console.log(">>> postInfoResponse >>>", _response);
  }
};

export const getTodayMatches = async () => {
  const _postResult = await postInfoResponse(process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BACKEND_API + process.env.REACT_APP_TODAYS_PREFIX, "TODAYS");
  console.log('--------->', _postResult?.data.length);
  return _postResult?.data;
  // var date = new Date();
  // var year = date.toLocaleString("default", { year: "numeric" });
  // var month = date.toLocaleString("default", { month: "2-digit" });
  // var day = date.toLocaleString("default", { day: "2-digit" });
  // // var today = `${year}-${month}-${day}`;
  // var today = `${day}/${month}/${year}`;

  // // const _postResult = await axios.get(`matches/${today}`);
  // const _postResult = await axios.get(`11550141/statistics`);
  // console.log('--------->', _postResult);
  // return _postResult?.data.events;
};

export const getLastMatches = async () => {
  const _postResult = await postInfoResponse(process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BACKEND_API + process.env.REACT_APP_LASTS_PREFIX, "LASTS");
  // console.log('--------->', _postResult?.data);
  return _postResult?.data;
};

export const getUpcomingMatches = async () => {
  const _postResult = await postInfoResponse(process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BACKEND_API + process.env.REACT_APP_UPCOMINGS_PREFIX, "UPCOMINGS");
  return _postResult?.data;
};

export const getLiveMatches = async () => {
  const _postResult = await postInfoResponse(process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BACKEND_API + process.env.REACT_APP_LIVES_PREFIX, "LIVES");
  return _postResult?.data;
};

export const getLiveStatistics = async (matchID) => {
  const _postData = { matchID };
  const _postResult = await postInfoResponse(process.env.REACT_APP_BACKEND_URL + process.env.REACT_APP_BACKEND_API + process.env.REACT_APP_STATISTICS_PREFIX, _postData);
  if (_postResult.data !== "STATISTICS") {
    return false;
  } else {
    return true;
  }
};