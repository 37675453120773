import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import NavBar from './components/navbar';
import Table from './components/table';
// import { getTodayMatches, getLastMatches, getUpcomingMatches, getLiveMatches } from './lib/basketAPI';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const App = () => {
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [init, setInit] = useState(false);
  // const [loading, setLoading] = useState(true);
  // const [todayMatches, setTodayMatches] = useState([]);
  // const [lastMatches, setLastMatches] = useState([]);
  // const [upcomingMatches, setUpcomingMatches] = useState([]);
  // const [liveMatches, setLiveMatches] = useState([]);

  // const fetchData = async () => {
  //   try {
  //     setLoading(true);
  //     const [today, last, upcoming, live] = await Promise.all([
  //       getTodayMatches(),  
  //       getLastMatches(),
  //       getUpcomingMatches(),
  //       getLiveMatches(),
  //     ]);
  //     setLastMatches(last || []);
  //     setUpcomingMatches(upcoming || []);
  //     setLiveMatches(live || []);
  //     setTodayMatches(today || []);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //     if (!init) setInit(true);
  //   }
  // };

  useEffect(() => {
    
    // fetchData();

    // const interval = setInterval(() => {
    //   fetchData();
    // }, 15000);

    // return () => {
    //   clearInterval(interval);
    // };
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <NavBar />
      {(loading && !init) ? (
        <div className="flex-grow bg-white text-center p-10 flex justify-center items-center">
          <Box>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Table category={"todayMatches"} />} />
            <Route path="/getTodays" element={<Table category={"todayMatches"} />} />
            <Route path="/getLasts" element={<Table category={"lastMatches"} />} />
            <Route path="/getUpcomings" element={<Table category={"upcomingMatches"} />} />
            <Route path="/getLives" element={<Table category={"liveMatches"} />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;